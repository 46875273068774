import {Directive, ElementRef, HostListener, Input} from '@angular/core';

/**
 * Created by sahilb@evision.ca on 2021-12-01.
 */
@Directive({
  selector: '[eCaseNDecimalNumber]',
})
export class ECaseNDecimalNumberDirective {
  private numberOfDecimalPlacesAllowed = 2;
  // Regex for validating numbers: allows optional minus, digits, optional decimal separator (., or ,), and digits after the separator
  private validNumberRegex: RegExp = /^-?\d+([.,]\d+)?$/g;
  // List of special keys to always be allowed
  private specialKeys: Array<string> = [
    'Backspace', 'Tab', 'Enter', 'Shift', 'Control', 'Alt',
    'Pause', 'CapsLock', 'Escape', 'Space', 'PageUp',
    'PageDown', 'End', 'Home', 'ArrowLeft', 'ArrowUp',
    'ArrowRight', 'ArrowDown', 'Insert', 'Delete',
    'F1', 'F2', 'F3', 'F4', 'F5', 'F6', 'F7',
    'F8', 'F9', 'F10', 'F11', 'F12'
  ];

  constructor(private el: ElementRef) {
  }

  @Input()
  set eCaseNDecimalNumber(val: any) {
    if (ECaseNDecimalNumberDirective.validateInput(val)) {
      this.numberOfDecimalPlacesAllowed = Number(val);
      this.validNumberRegex = new RegExp('^-?\\d+([.,]\\d{0,' + this.numberOfDecimalPlacesAllowed + '})?$', 'g');
    }
  }

  private static validateInput(val: any): boolean {
    const isNotValid = isNaN(val);
    if (isNotValid) {
      throw new TypeError('ECaseNDecimalNumberDirective.validateInput > Value passed in directive \'eCaseNDecimalNumber\' is not a number');
    }
    return isNotValid;
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    // Allow special keys (and stop processing)
    if (this.specialKeys.includes(event.key)) {
      return;
    }
    const currentValue: string = this.el.nativeElement.value.toString();
    const selectionStart = this.el.nativeElement.selectionStart;
    const selectionEnd = this.el.nativeElement.selectionEnd;
    this.validNumberRegex = new RegExp('^-?\\d+([.,]\\d{0,' + this.numberOfDecimalPlacesAllowed + '})?$', 'g');
    // Handle decimal separators
    if (['Decimal', '.', ','].includes(event.key) && /[.,]/.test(currentValue)) {
      if (this.numberOfDecimalPlacesAllowed == 0) {
        this.el.nativeElement.value = currentValue.split(',').join('').split('.').join('');
        this.el.nativeElement.selectionStart = selectionStart;
        this.el.nativeElement.selectionEnd = selectionEnd;
        event.preventDefault();
        return;
      } else if (this.numberOfDecimalPlacesAllowed > 0) {
        if (/[.,]/g.test(currentValue.substring(selectionStart, selectionEnd))) {
          // Allow changing the decimal separator by another
          return;
        }
        let t = 0;
        this.el.nativeElement.value = currentValue.replace(/[.,]/g, (substring: string) => {
          t++;
          return (t < 2) ? substring : '';
        });
        this.el.nativeElement.selectionStart = selectionStart;
        this.el.nativeElement.selectionEnd = selectionEnd;
        event.preventDefault();
        return;
      }
    }
    // Make sure that the value is to be kept valid
    // Simulate the final value as if the event was not prevented
    const finalString =
      currentValue.slice(0, selectionStart)  // Text before the caret
      + event.key                            // The key being pressed
      + currentValue.slice(selectionEnd);    // Text after the caret
    if (currentValue && !this.validNumberRegex.test(finalString)) {
      event.preventDefault();
      return;
    }
    // The character is accepted
    console.log("ECaseNDecimalNumberDirective.onKeyDown > " + finalString)
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent): void {
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const pastedData = clipboardData.getData("text");
    this.validNumberRegex = new RegExp('^-?\\d+([.,]\\d{0,' + this.numberOfDecimalPlacesAllowed + '})?$', 'g');
    // Check if the pasted data matches the regex
    if (!this.validNumberRegex.test(pastedData)) {
      // Block the paste if it doesn't match the pattern
      event.preventDefault();
      console.log("ECaseNDecimalNumberDirective.onPaste > Only numerical values are allowed (decimals with '.' or ',' and negatives are permitted).");
    }
  }
}