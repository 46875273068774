import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../dialog/dialog.component';
import {
  EcaseHttpService,
  ECaseNumberFormatterPipe,
  ECaseSnackBarService,
  ECaseUtils, ParametersService,
  PendingChangesGuard,
  RefreshDataService
} from 'synto-common';
import { TranslateService } from '@ngx-translate/core';
import cloneDeep from 'lodash/cloneDeep';

@Component({
  selector: 'fb-budget-expenditure-items',
  templateUrl: './fb-budget-expenditure-items.component.html',
  styleUrls: ['./fb-budget-expenditure-items.component.scss']
})
export class FbBudgetExpenditureItemsComponent implements OnInit, OnDestroy {

  @Input() url: any;
  @Input() selectedLanguage;
  @Input() bphChange: any;
  @Input() confChange: any;
  @Input() isValidHtml: boolean;
  @Input() isValidTable: boolean;
  @Input() isValidTableReadonly: boolean;
  @Input() globalConf: any;
  @Input() globalPrj: any;
  @Input() selectedSectionId;
  @Input() formId;
  @Input() blockIndex: number;
  @Output() emitOutputEvent = new EventEmitter<any>();
  @Output() emitSaveEvent = new EventEmitter<any>();
  @Output() emitOutputEventForBudgetTableAddRow = new EventEmitter<any>();

  hasOptionSelected: boolean;
  isShowTableRow: boolean;
  selectedTable: any;
  showAddTableForm: boolean;
  categoryList: any = {};
  tempRow: any = {};
  beforeEditRow: any = {};
  indexOfEditedRow;
  isOtherBudgetItemTextAreaEnabled = false;
  summaryTableDefaultLabel = {
    'en': 'Summary table',
    'fr': 'Sommaire',
  }

  constructor(public dialog: MatDialog,
              private translate: TranslateService,
              private http: EcaseHttpService,
              private eCaseSnackBarService: ECaseSnackBarService,
              private eCaseNumberFormatterPipe: ECaseNumberFormatterPipe,
              private pendingChangesGuard: PendingChangesGuard,
              private refreshDataService: RefreshDataService,
              private parametersService: ParametersService,
              private translateService: TranslateService) {
    this.isOtherBudgetItemTextAreaEnabled = this.parametersService.parameter['/core/ui/form/budget_expenditure_item_component/other_budget_item_text_area/enabled'];
  }

  getTextLength2(l): number {
    try {
      l = l.toString();
      if (l.length > 9) {
        l = l.slice(0, 9);
      }
      return l.length;
    } catch (e) {
      return 0;
    }
  }
  getTextLength3(l): number {
    try {
      l = l.toString();
      if (l.length > 2) {
        l = l.slice(0, 2);
      }
      return l.length;
    } catch (e) {
      return 0;
    }
  }

  getTextLength(l): number {
    try {
      if (l.toString().length <= this.confChange.maxlength) {
        return l ? l.toString().length : 0;
      } else {
        return this.confChange.maxlength;
      }
    } catch (e) {
      return 0;
    }
  }

  getWordCount(str): number {
    try {
      return (str === '' ? 0 : str.split(' ').filter(item => item !== '').length);
    } catch (e) {
      return 0;
    }
  }

  getTranslation(key: string): string {
    const value = ECaseUtils.getTranslatedValueFromKey(this.translate, key, this.selectedLanguage);
    return value ? value : '';
  }

  changeValueEvent(tempObject, key) {
    if (!this.confChange.isMaxLengthWords) {
      console.log('test');
      if (this.getTextLength(tempObject) >= this.confChange.maxlength) {
        this.tempRow[key].value = tempObject.split('').slice(0, this.confChange.maxlength).join('');
      }
    } else {
      if (this.getWordCount(tempObject) >= this.confChange.maxlength) {
        tempObject = tempObject.toString().split(' ').slice(0, this.confChange.maxlength).join(' ');
        this.tempRow[key].value = tempObject;
      }
    }
    this.pendingChangesGuard.isPristine = false;
  }

  ngOnInit() {
    console.log('************FB-BUDGET T1***********');
    console.log(this.bphChange);
    console.log(this.confChange);

    this.initializeTable();
    console.log("GOT INSIDE THE REFRESH BUDGET TABLE!!!!!! 2");
    this.refreshDataService.getChangesBudgetExpenditureEvent().subscribe((newData) => {
      console.log("GOT INSIDE THE REFRESH BUDGET TABLE!!!!!!");
        if(newData && (newData.name === this.confChange.name) && newData.years) {
          this.confChange.numberOfYears = newData.years;

          this.bphChange.budgetTables.forEach((table) => {
            table.rows = [];
            table.yearRows = [];
            for (let i = 0; i < this.confChange.numberOfYears; i++) {
              table.yearRows.push({
                'value': 0,
                'error': {}
              });
            }

          });
          this.bphChange.yearRows = [];
          for (let i = 0; i < this.confChange.numberOfYears; i++) {
            this.bphChange.yearRows.push({
              'value': 0,
              'error': {}
            });
          }
          console.log("GOT INSIDE THE REFRESH BUDGET TABLE!!!!!! 2");
          this.generateSummaryTable();
          this.generateCategoryTeamTable(this.bphChange.budgetTables);
        }
    });
  }



  initializeTable() {
    if (!this.confChange.otherBudgetItemLabel) {
      this.confChange.otherBudgetItemLabel = {};
      this.translate.langs.forEach(lang => {
        this.confChange.otherBudgetItemLabel[lang] = ECaseUtils.getTranslatedValueFromKey(this.translateService, 'ecase.common.default.otherBudgetItem.label')
      });
    }

    // TODO conf to add to admin
    this.confChange.fundsSplitInstitution = false;
    this.confChange.hasComments = false;
    // this.confChange.subtotalDetailLabel = {en:'Amount ($)', fr:'Amount ($)'};
    this.confChange.addLabel = { en: 'Add an item', fr: 'Ajouter' };

    this.generateBudgetTables();
    this.generateUploadConf();
    this.bphChange.budgetTables = this.bphChange.budgetTables.filter(table => table.showLabel);

    this.bphChange.budgetTables.forEach((table) => {
      table.categoryList = [];
    });
    this.refreshDataService.getAllExpenditureItems(this.globalPrj.cpmCompetitionID).subscribe((item) => {
      if (item.status) {
        // TODO we could make titles between opex/capex
        // TODO we could split by chart

        const categoryList = {};
        // NO SPLIT
        categoryList['general'] = item.expenditureItems.map((expenditure) => {
          expenditure.value = expenditure.childExpenditureId;
          expenditure.label = expenditure.childExpenditure;
          expenditure.children = expenditure.children.map((child) => {
            child.value = child.childExpenditureId;
            child.label = child.childExpenditure;
            return child;
          }).sort((a, b) => a.sortingKey > b.sortingKey);
          return expenditure;
        });
        categoryList['general'] = categoryList['general'].sort((a, b) => a.sortingKey > b.sortingKey);

        /* TODO if split by capex/opex, needs modifications to backend to include capex/opex in the service
        if (false) {
          categoryList['OPEX'] = item.expenditureItems.map((expenditure) => {
            expenditure.value = expenditure.childExpenditureId;
            expenditure.label = expenditure.childExpenditure;
            expenditure.children = expenditure.children.map((child) => {
              child.value = child.childExpenditureId;
              child.label = child.childExpenditure;
              return child;
            });
            return expenditure;
          });

          categoryList['CAPEX'] = item.expenditureItems.map((expenditure) => {
            expenditure.value = expenditure.childExpenditureId;
            expenditure.label = expenditure.childExpenditure;
            expenditure.children = expenditure.children.map((child) => {
              child.value = child.childExpenditureId;
              child.label = child.childExpenditure;
              return child;
            });
            return expenditure;
          });
        } */
        this.bphChange.budgetTables.forEach((table) => {
          if (categoryList[table.listType]) {
            table.categoryList = categoryList[table.listType];
          } else {
            table.categoryList = categoryList['general'];
          }
        });
      }
    });

    if (!this.confChange.yearLabel) {
      this.confChange.yearLabel = { 'en': 'Year', 'fr': 'Année' };
    }
    if (!this.confChange.numberOfYears) {
      this.confChange.numberOfYears = 3;
    }
    if (!this.globalPrj.upload_to_be_deleted) {
      this.globalPrj.upload_to_be_deleted = [];
    }
    this.refreshDataService.bphChange = this.bphChange;

    if (!this.bphChange.yearRows) {
      this.bphChange.yearRows = [];
      for (let i = 0; i < this.confChange.numberOfYears; i++) {
        this.bphChange.yearRows.push({
          'value': 0,
          'error': {}
        });
      }
    }

    console.log('===============================');
    console.log(this.bphChange.budgetTables);

    this.bphChange.budgetTables.forEach((table) => {
      if (!table.yearRows) {
        table.yearRows = [];
        for (let i = 0; i < this.confChange.numberOfYears; i++) {
          table.yearRows.push({
            'value': 0,
            'error': {}
          });
        }
      }
    });

    this.bphChange.budgetTables.forEach((table) => {
      table.rows.forEach((row) => {
        if (!row.description) {
          row.description = {};
        }
        if (!row.justification) {
          row.justification = {};
        }
        if (!row.durationMonths) {
          row.durationMonths = {};
        }
        if (!row.subCategory) {
          row.subCategory = {};
        }
        if (!row.category) {
          row.category = {};
        }
        if (!row.otherBudgetItem) {
          row.otherBudgetItem = {};
        }
        if (!row.comments) {
          row.comments = {};
        }
        if (!row.expandRows) {
          row.expandRows = [];
        }
        row.description.error = {};
        row.justification.error = {};
        row.durationMonths.error = {};
        row.subCategory.error = {};
        row.category.error = {};
        row.comments.error = {};
        row.expandRows.forEach((expandRow) => {
          if (!expandRow.subTotal || expandRow.subTotal === '') {
            expandRow.subTotal = 0;
          }

          expandRow.subTotal.error = {};
          if (this.confChange.fundsSplitInstitution) {
            if (!expandRow.fundsFromOtherInstitution || expandRow.fundsFromOtherInstitution === '') {
              expandRow.fundsFromOtherInstitution = 0;
            }
            if (!expandRow.fundsFromInstitution || expandRow.fundsFromInstitution === '') {
              expandRow.fundsFromInstitution = 0;
            }
            expandRow.fundsFromInstitution.error = {};
            expandRow.fundsFromOtherInstitution.error = {};
          }

        });
      });
    });

    this.bphChange.budgetTables.forEach((table) => {
      this.calculateYearTotals(table);
    });
    this.bphChange.budgetTables[0].teamFunded = '1';
    this.generateSummaryTable();
    this.generateCategoryTeamTable(this.bphChange.budgetTables);
    this.bphChange.isOtherBudgetItemTextAreaEnabled = this.isOtherBudgetItemTextAreaEnabled;

  }

  truncateText(text: string) {
    return text ? text.substring(0, 20) + '...' : '';
  }

  generateBudgetTables() {
    if (!this.bphChange.budgetTables) {
      this.bphChange.budgetTables = [];
    }


    // TODO: we could make type/title between opex/capex
    // TODO: we could split by chart

    // Budget Team
    if (this.confChange.budgetPerTeam) {
      switch (this.confChange.budgetPerTeamType) {
        case 'list':
          // TODO: code lists
          break;
        case 'table':
          console.log('---------GLOBALPRJ-----------');
          console.log(this.globalPrj);
          const thisRef = this;
          if (this.confChange.tableSection && this.confChange.tableComponent && this.confChange.tableSubComponent) {
            this.globalPrj[this.confChange.tableSection][this.confChange.tableComponent].rows.forEach((row, index) => {
              let teamName = row[thisRef.confChange.tableSubComponent].value;
              console.log('---------TEAMNAME-----------', teamName);
              console.log('---------caseApplicationTeamId-----------', row.caseApplicationTeamId);
              console.log('---------index-----------', row.uniqueRowIndex);
              if (typeof teamName !== 'string') {
                teamName = teamName.label;
              } else {
                teamName = {
                  'fr': teamName,
                  'en': teamName,
                  'ar': teamName
                };
              }
              const uniqueTable = thisRef.bphChange.budgetTables.filter(table => table.id === row.uniqueRowIndex)[0];
              if (uniqueTable) {
                // Rename table if label has changed
                uniqueTable.label = teamName;
                uniqueTable.caseApplicationTeamId = row.caseApplicationTeamId;
              } else {
                // add table if it does not exist
                let hasOptionSelected = false;
                if (index === 0) {
                  hasOptionSelected = true;
                }
                thisRef.bphChange.budgetTables.push(thisRef.getTableConfig(true, teamName, '', hasOptionSelected, row.uniqueRowIndex, row.caseApplicationTeamId));
                this.bphChange.budgetTables[0].teamFunded = '1';
              }
            });
          }
          break;
      }
      // Default one table budget
    } else {
      if (this.bphChange.budgetTables.length === 0) {
        this.bphChange.budgetTables.push(this.getTableConfig(true));

      }
    }
    this.bphChange.budgetTables[0].teamFunded = '1';

  }

  getTableConfig(showLabel = false, label: any = '', listType = '', hasOptionSelected = true, id: any = false, caseApplicationTeamId: any = false) {
    if (typeof label === 'string') {
      label = {
        'fr': label,
        'en': label,
        'ar': label
      };
    }
    const table = {
      'showLabel': showLabel,
      'label': label,
      'listType': listType,
      'hasOptionSelected': hasOptionSelected,
      'id': id,
      'caseApplicationTeamId': caseApplicationTeamId,
      'rows': []
    };
    return table;
  }

  calculateTableTotals() {
    this.bphChange.budgetTables.forEach((table) => {
      table.rows.forEach((item) => {
        this.onInstitutionFundsInput(table, item);
        this.onSubTotalInput(table, item);
        this.onOtherInstitutionFundsInput(table, item);

      });
    });
  }

  getTranslatedLabel(key: string): string {
    const value = ECaseUtils.getTranslatedValueFromKey(this.translate, key, this.selectedLanguage);
    return value ? value : '';
  }


  ngOnDestroy() {
    this.emitOutputEvent.emit(true);
    this.refreshDataService.toggleShowSaveNavBar(true);
  }

  openDialog(dataToDisplay) {
    if (typeof dataToDisplay === 'string') {
      const dummyString = dataToDisplay;
      dataToDisplay = {};
      this.translateService.langs.forEach((lang) => {
        dataToDisplay[lang] = dummyString;
      });
    }
    this.dialog.open(DialogComponent, {
      width: '600px',
      data:
        {
          dialog: dataToDisplay,
          selectedLanguage: this.selectedLanguage
        }
    });
  }


  showTableRow(row, index) {
    console.log(index);
    row.isShowTableRow = !row.isShowTableRow;
  }
  onSubTotalInput2($event?) {
    if ($event) {
      const t = $event.target;
      if (t.hasAttribute('maxlength')) {
        t.value = t.value.slice(0, t.getAttribute('maxlength'));
      }
    }
  }
  onSubTotalInput(table, row, expandRow?, $event?) {

    if ($event && expandRow.subTotal !== null) {
      const t = $event.target;
      if (t.hasAttribute('maxlength')) {
        t.value = t.value.slice(0, t.getAttribute('maxlength'));
        if (!expandRow.subTotal) {
          expandRow.subTotal = {};
        }
        expandRow.subTotal.value = expandRow.subTotal.value ? expandRow.subTotal.value : 0;
        expandRow.subTotal.value = +((expandRow.subTotal.value).toString().slice(0, t.getAttribute('maxlength')));
        console.log('SUBTOTAL', expandRow.subTotal);
      }
    }

    let total = 0;
    if (row.expandRows) {
      for (const expandRow of row.expandRows) {
        total = +(total + expandRow.subTotal.value);
      }
    }

    if (!row.grandSubTotal) {
      row.grandSubTotal = {};
    }
    row.grandSubTotal.value = total;
    console.log(row);
    if (this.confChange.fundsSplitInstitution) {
      if (expandRow) {
        expandRow.fundsFromOtherInstitution.value = expandRow.subTotal.value - expandRow.fundsFromInstitution.value;
        this.onOtherInstitutionFundsInput(table, row);
      }
    }
    this.pendingChangesGuard.isPristine = false;
  }

  checkForSelectNo($event, table) {
    console.log('LLLLLLLLLLLLLL');
    console.log(table.teamFunded);
    console.log(table);
    table.rows = [];
    if (table.teamFunded === '2') {
      table.hidden = true;
      this.removeTableRowsFromSummary(table);
    } else {
      table.hidden = false;
    }
    this.generateSummaryTable();
  }

  removeTableRowsFromSummary(table) {
    if (table && table.rows) {
      table.rows.forEach((row) => {
        this.modifyRowSummaryTable(row, true);
      });
    }
  }

  calculateYearTotals(table) {
    if (table) {
      const yearRows = [];
      for (let i = 0; i < this.confChange.numberOfYears; i++) {
        let yearCount = 0;
        table.rows.filter(item => !item.isCategoryTotal && !item.isTotal).forEach((item) => {
          if (item.expandRows[i]) {
            yearCount += +(item.expandRows[i].subTotal.value);
          }
        });
        yearRows.push({
          'value': yearCount,
          'error': {}
        });
      }
      table.yearRows = yearRows;
    }
    console.log(table);
  }

  onInstitutionFundsInput(table, row, expandRow?, $event?) {
    let total = 0;

    if ($event && expandRow.subTotal !== null) {
      const t = $event.target;
      if (t.hasAttribute('maxlength')) {
        t.value = t.value.slice(0, t.getAttribute('maxlength'));
        expandRow.fundsFromInstitution.value = +((expandRow.fundsFromInstitution.value ? expandRow.fundsFromInstitution.value : 0).toString().slice(0, t.getAttribute('maxlength')));
        console.log('Institution TOTAL: ', expandRow.fundsFromInstitution.value);
      }
    }

    if (row.expandRows) {
      for (const expandRow of row.expandRows) {
        total = +(total + expandRow.fundsFromInstitution.value);
      }
    }
    if (!row.grandTotalForInstitutionFunds) {
      row.grandTotalForInstitutionFunds = {};
    }
    row.grandTotalForInstitutionFunds.value = total;
    if (expandRow) {
      if (!expandRow.fundsFromOtherInstitution) {
        expandRow.fundsFromOtherInstitution = {};
      }
      expandRow.fundsFromOtherInstitution.value = expandRow.subTotal.value - expandRow.fundsFromInstitution.value;
      this.onOtherInstitutionFundsInput(table, row);
    }
    this.pendingChangesGuard.isPristine = false;
  }

  onOtherInstitutionFundsInput(table, row) {
    let total = 0;
    if (row.expandRows) {
      for (const expandRow of row.expandRows) {
        total = +(total + expandRow.fundsFromOtherInstitution.value);
      }
    }
    if (!row.grandTotalForOtherFunds) {
      row.grandTotalForOtherFunds = {};
    }
    row.grandTotalForOtherFunds.value = total;
    this.pendingChangesGuard.isPristine = false;
  }

  filterInvalidCategories(categories: any): any {
    const validKeys = Object.keys(categories).filter(key => !key.includes('undefined'));
    const obj = cloneDeep(categories);
    Object.keys(obj).forEach((key) => {
      if (!validKeys.includes(key)) {
        delete categories[key];
      }
    });
    return categories;
  }

  onDeleteTable(row, rowIndex, table) {
    if (this.confChange.isConfirmDelete) {
      this.confChange.confirmDelete(this.globalPrj, this.globalConf, rowIndex);
    }
    row.uploadFile.value.forEach((upl) => {
      this.globalPrj.upload_to_be_deleted.push(upl.id);
    });
    this.modifyRowSummaryTable(table.rows[rowIndex], true);
    const categoryToBeDeleted = table.rows[rowIndex].category.value;
    table.rows.splice(rowIndex, 1);
    const isCategoryDefined = [];
    this.bphChange.budgetTables.forEach((budgetTable) => {
      isCategoryDefined.push(budgetTable.rows.some(_row => _row.category && categoryToBeDeleted && _row.category.value === categoryToBeDeleted));
    });
    if (!isCategoryDefined.includes(true)) {
      delete this.bphChange.summaryTable.categories['c' + categoryToBeDeleted];
    }
    this.onSubTotalInput(table, {});
    this.onOtherInstitutionFundsInput(table, {});
    this.onInstitutionFundsInput(table, {});
    this.reshuffleTable(table);
    if (this.confChange.isAfterDeleteFunctionActive) {
      this.confChange.afterDeleteFunction(this.globalPrj, this.globalConf, rowIndex);
    }
    this.pendingChangesGuard.isPristine = false;
    this.generateCategoryTeamTable(this.bphChange.budgetTables);
  }

  onEditTable(row, rowIndex, table) {
    this.selectedTable = table;
    this.showAddTableForm = true;
    this.tempRow = cloneDeep(row);
    this.beforeEditRow = cloneDeep(row);
    console.log(this.tempRow);
    this.indexOfEditedRow = rowIndex;
    this.emitOutputEvent.emit(false);
    this.refreshDataService.toggleShowSaveNavBar(false);
    //THIS SECTION;
    /*for (const key in this.globalPrj.project_budget_operating_opex) {
      if (key !== this.confChange.name && this.globalPrj.project_budget_operating_opex[key] && this.globalPrj.project_budget_operating_opex[key].globalShowDetail !== undefined) {
        this.globalPrj.project_budget_operating_opex[key].globalShowDetail = false;
      }
    }*/
    const globalArray = [];
    for (const x in this.globalPrj[this.selectedSectionId]) {
      console.log(x);
      if (x !== this.confChange.name) {
        globalArray.push(x);
      }
    }
    for (let k = 0; k < globalArray.length; k++) {
      try {
        ((this.globalPrj[this.selectedSectionId])[globalArray[k]]).globalShowDetail = false;
        ((this.globalConf[this.selectedSectionId])[globalArray[k]]).showDetails = true;
      } catch (e) {
      }
    }
  }

  onAddTable(table) {
    this.selectedTable = table;
    const tempObject: any = {
      'category': {
        label: {},
        value: '',
        error: {}
      },
      'otherBudgetItem': {
        value: ''
      },
      'subCategory': {
        label: {},
        value: '',
        error: {}
      },
      'uploadFile': {
        value: [],
        error: {},
        globalShowDetail: true
      },
      'description': {
        value: '',
        error: {}
      },
      'justification': {
        value: '',
        error: {}
      },
      'durationMonths': {
        value: '',
        error: {}
      },
      'comments': {
        value: '',
        error: {}
      },
      'grandSubTotal': {
        value: '',
        error: {}
      },
      'grandTotalForInstitutionFunds': {
        value: '',
        error: {}
      },
      'grandTotalForOtherFunds': {
        value: '',
        error: {}
      },
      'isRowAdded': false,
      'expandRows': [],
      'isShowTableRow': false
    };
    tempObject.uniqueRowIndex = table && table.rows.length > 0 ? (Math.max.apply(Math, table.rows.map((o) => {
      return o.uniqueRowIndex ? o.uniqueRowIndex : 0;
    })) + 1) : 1;

    if (this.confChange.numberOfYears) {
      for (let i = 0; i < this.confChange.numberOfYears; i++) {
        const yearLabelObject = cloneDeep(this.confChange.yearLabel);
        Object.keys(this.confChange.yearLabel).forEach((key) => {
          yearLabelObject[key] = this.confChange.yearLabel[key] + ' ' + (i + 1);
        });
        let expandRow: any = {
          'label': yearLabelObject,
          'subTotal': {
            'value': '',
            'error': {}
          }
        };
        if (this.confChange.fundsSplitInstitution) {
          expandRow = {
            'label': yearLabelObject,
            'subTotal': {
              'value': '',
              'error': {}
            },
            'fundsFromInstitution': {
              'value': '',
              'error': {}
            },
            'fundsFromOtherInstitution': {
              'value': '',
              'error': {}
            },
          };
        }
        tempObject.expandRows.push(expandRow);
      }
    }

    this.tempRow = tempObject;
    this.beforeEditRow = cloneDeep(tempObject);
    // this.emitOutputEvent.emit(false);
    // this.emitOutputEventForBudgetTableAddRow.emit(this.confChange);
    this.showAddTableForm = true;
    this.indexOfEditedRow = undefined;
    this.emitOutputEvent.emit(false);
    this.refreshDataService.toggleShowSaveNavBar(false);

    const globalArray = [];
    for (const x in this.globalPrj[this.selectedSectionId]) {
      console.log(x);
      if (x !== this.confChange.name) {
        globalArray.push(x);
      }
    }
    for (let k = 0; k < globalArray.length; k++) {
      try {
        ((this.globalPrj[this.selectedSectionId])[globalArray[k]]).globalShowDetail = false;
        ((this.globalConf[this.selectedSectionId])[globalArray[k]]).showDetails = true;
      } catch (e) {
      }
    }
    // this.selectedTable.categoryList = this.selectedTable.categoryList.sort((a, b) => {
    //   const x = (a.label[this.selectedLanguage]).toLowerCase();
    //   const y = (b.label[this.selectedLanguage]).toLowerCase();
    //   return x.localeCompare(y);
    // });
    console.log('666666666666666');
    console.log(this.selectedTable.categoryList);
    this.selectedTable.categoryList = this.selectedTable.categoryList.sort((rowa, rowb) => {
      if (rowa.sortingKey < rowb.sortingKey) {
        return -1;
      } else if (rowa.sortingKey > rowb.sortingKey) {
        return 1;
      }
      return 0;
    });

    console.log('55555555555555555555');
    console.log(this.selectedTable.categoryList);

    // this.tempRow.category.children = this.tempRow.category.children.sort((a, b) => {
    //   const x = (a.label[this.selectedLanguage]).toLowerCase();
    //   const y = (b.label[this.selectedLanguage]).toLowerCase();
    //   return x.localeCompare(y);
    // });
    console.log("Yo Yo", this.bphChange);
  }

  confirm() {
    console.log('confirm');
    const tempObject = cloneDeep(this.tempRow);
    const categoryToBeUpdated = this.beforeEditRow.category.value;
    if (this.indexOfEditedRow !== undefined) {
      this.modifyRowSummaryTable(this.selectedTable.rows[this.indexOfEditedRow], true);
      this.modifyRowSummaryTable(tempObject);
      this.selectedTable.rows[this.indexOfEditedRow] = tempObject;
    } else {
      this.modifyRowSummaryTable(tempObject);
      this.selectedTable.rows.push(tempObject);
    }

    this.onSubTotalInput(this.selectedTable, tempObject);
    if (this.confChange.fundsSplitInstitution) {
      this.onOtherInstitutionFundsInput(this.selectedTable, tempObject);
      this.onInstitutionFundsInput(this.selectedTable, tempObject);
    }

    const isCategoryDefined = [];
    this.bphChange.budgetTables.forEach((budgetTable) => {
      isCategoryDefined.push(budgetTable.rows.some(_row => _row.category && categoryToBeUpdated && _row.category.value === categoryToBeUpdated));
    });
    if (!isCategoryDefined.includes(true)) {
      delete this.bphChange.summaryTable.categories['c' + categoryToBeUpdated];
    }

    this.reshuffleTable(this.selectedTable);

    if (this.confChange.isAfterSaveFunctionActive) {
      if (typeof this.confChange.afterSaveFunction === 'function') {
        this.confChange.afterSaveFunction(this.globalPrj, this.globalConf, this, this.bphChange);
      }
    }

    this.emitOutputEvent.emit(true);
    this.refreshDataService.toggleShowSaveNavBar(true);
    this.showAddTableForm = false;


    const globalArray = [];
    for (const x in this.globalPrj[this.selectedSectionId]) {
      if (x !== this.confChange.name) {
        globalArray.push(x);
      }
    }
    for (let k = 0; k < globalArray.length; k++) {
      try {
        ((this.globalPrj[this.selectedSectionId])[globalArray[k]]).globalShowDetail = true;
        ((this.globalConf[this.selectedSectionId])[globalArray[k]]).showDetails = false;
      } catch (e) {
      }
    }
    this.pendingChangesGuard.isPristine = false;
    this.generateCategoryTeamTable(this.bphChange.budgetTables);
  }
  reshuffleTable(table) {
    table.rows = table.rows.filter(row => !row.isTotal);
    /*table.rows.sort(function(rowa, rowb) {
      return (rowa.category.value*1000000 - rowb.category.value*1000000) + rowa.subCategory.value - rowb.subCategory.value;
    });*/
    table.rows.sort((rowa, rowb) => {
      if (rowa.category.sortingKey < rowb.category.sortingKey) {
        return -1;
      } else if (rowa.category.sortingKey > rowb.category.sortingKey) {
        return 1;
      }
      return 0;
    });
    // ajout des lignes de totaux

    let index = 0;
    let oldCat: any = {};
    let oldSubCat: any = {};
    let catSubTotal = 0;
    let subCatSubTotal = 0;
    let bigTotal = 0;
    while (table.rows[index]) {
      oldCat = table.rows[index].category;
      oldSubCat = table.rows[index].subCategory;
      subCatSubTotal += table.rows[index].grandSubTotal.value;
      catSubTotal += subCatSubTotal;
      // catSubTotal += table.rows[index].grandSubTotal.value;
      bigTotal += table.rows[index].grandSubTotal.value;
      index++;


      if (!table.rows[index] || (oldSubCat && oldSubCat.value !== table.rows[index].subCategory.value)) {
        const totalRow = {
          totalText: {
            en: oldSubCat.label['en'] + ' Total',
            fr: oldSubCat.label['fr'] + ' Total'
          },
          total: subCatSubTotal,
          isTotal: true
        };
        table.rows.splice(index, 0, totalRow);
        index++;
        subCatSubTotal = 0;
      }
      if ((!oldCat.children || oldCat.children.length >= 1) && (!table.rows[index] || oldCat.value !== table.rows[index].category.value)) {
        const expandRows = [];
        for (let i = 0; i < this.confChange.numberOfYears; i++) {
          const subTotal = table.rows.filter(item => !item.isTotal).filter(item => item.category.value === oldCat.value)
            .map(item => Number(item.expandRows[i].subTotal.value)).reduce((a, b) => a + b, 0);
          expandRows[i] = expandRows[i] ? expandRows[i] : { 'subTotal': { 'value': subTotal } };
        }
        const _totalRow = {
          isCategoryTotal: true,
          totalText: {
            en: 'Total ' + oldCat.label['en'],
            fr: 'Total ' + oldCat.label['fr']
          },
          expandRows: expandRows,
          total: catSubTotal,
          isTotal: true
        };
        table.rows.splice(index, 0, _totalRow);
        index++;
        catSubTotal = 0;
      }
      // catSubTotal = 0;
      subCatSubTotal = 0;

    }
    console.log('=======catSubTotalcatSubTotalcatSubTotal=============');
    console.log(catSubTotal);
    console.log(bigTotal);
    const _expandRows = [];
    for (let i = 0; i < this.confChange.numberOfYears; i++) {
      const subTotal = table.rows.filter(item => !item.isTotal)
        .map(item => Number(item.expandRows[i].subTotal.value)).reduce((a, b) => a + b, 0);
      _expandRows[i] = _expandRows[i] ? _expandRows[i] : { 'subTotal': { 'value': subTotal } };
    }
    const totalRow = {
      isCategoryTotal: true,
      totalText: {
        en: 'Grand total',
        fr: 'Grand total'
      },
      total: bigTotal,
      expandRows: _expandRows,
      isTotal: true
    };
    table.rows.push(totalRow);
    this.generateCategoryTeamTable(this.bphChange.budgetTables);
  }

  cancel() {
    this.showAddTableForm = false;
    this.emitOutputEvent.emit(true);
    this.refreshDataService.toggleShowSaveNavBar(true);

    const globalArray = [];
    for (const x in this.globalPrj[this.selectedSectionId]) {
      if (x !== this.confChange.name) {
        globalArray.push(x);
      }
    }
    for (let k = 0; k < globalArray.length; k++) {
      try {
        ((this.globalPrj[this.selectedSectionId])[globalArray[k]]).globalShowDetail = true;
        ((this.globalConf[this.selectedSectionId])[globalArray[k]]).showDetails = false;
      } catch (e) {
      }
    }
  }

  replaceDescriptionLabelWithYears(value) {
    if (value) {
      let newValue = value[this.selectedLanguage];
      newValue = newValue.replace('$numberOfYears', this.confChange.numberOfYears);
      return newValue;
    } else {
      return '';
    }
  }

  convertNumberToCurrencyFormat(value, currency) {
    return this.confChange.isFormattingDisabled ? value : this.eCaseNumberFormatterPipe.transform(value, currency, this.selectedLanguage, false, currency);
  }

  generateCategoryTeamTable(budgetTables) {
    const result = {
      data: {},
      "grand total": 0
    };

    const allTeams = new Set(budgetTables.map(table => table.label[this.selectedLanguage]));

    budgetTables.forEach(table => {
      const team = table.label[this.selectedLanguage];

      table.rows.forEach(row => {
        if (row.category && row.category.childExpenditure) {
          const category = row.category.childExpenditure[this.selectedLanguage];
          const value = row.grandSubTotal ? row.grandSubTotal.value : 0;

          if (!result.data[category]) {
            result.data[category] = { teams: {}, sum: 0 };
          }

          if (!result.data[category].teams[team]) {
            result.data[category].teams[team] = 0;
          }
          result.data[category].teams[team] += value;
          result.data[category].sum += value;
          result["grand total"] += value;
        }
      });
    });

    Object.keys(result.data).forEach(category => {
      allTeams.forEach(team => {
        if (!result.data[category].teams[team]) {
          result.data[category].teams[team] = 0;
        }
      });
    });

    console.log("------------I am Category vs Team-----------------");
    console.log(result);
    this.bphChange.categoryTeamData = result;
  }

  getTeamNames(categoryTeamData) {
    const teamNames = new Set();
    for (const category in categoryTeamData) {
      if (categoryTeamData.hasOwnProperty(category)) {
        const teams = categoryTeamData[category].teams;
        for (const team in teams) {
          if (teams.hasOwnProperty(team)) {
            teamNames.add(team);
          }
        }
      }
    }

    return Array.from(teamNames);
  }

  calculateTeamTotals(categoryTeamData) {
    const teamTotals = {};

    if (categoryTeamData && categoryTeamData.data) {
      Object.values(categoryTeamData.data).forEach(category => {
        // @ts-ignore
        if (category && category.teams) {
          // @ts-ignore
          Object.entries(category.teams).forEach(([team, value]) => {
            if (!teamTotals[team]) {
              teamTotals[team] = 0;
            }
            teamTotals[team] += value;
          });
        }
      });
    }

    return teamTotals;
  }


  generateSummaryTable() {
    console.log('generateSummaryTable');
    this.bphChange.yearRows = [];
    this.bphChange.grandSubTotal = {
      'value': 0,
      'error': {}
    };
    this.bphChange.grandTotalForInstitutionFunds = {
      'value': 0,
      'error': {}
    };
    this.bphChange.grandTotalForOtherFunds = {
      'value': 0,
      'error': {}
    };
    for (let i = 0; i < this.confChange.numberOfYears; i++) {
      if (!this.bphChange.yearRows) {
        this.bphChange.yearRows = [];
      }
      if (!this.bphChange.yearRows[i]) {
        this.bphChange.yearRows.push({
          'value': 0,
          'error': {}
        });
      }
    }

    this.bphChange.summaryTable = {};
    this.bphChange.summaryTable.categories = {};
    console.log("In generate summary table ",this.bphChange.summaryTable)
    this.bphChange.budgetTables.forEach((table) => {
      table.rows.forEach((row) => {
        this.modifyRowSummaryTable(row);
      });
    });
  }

  sortArray(arr: any[]): any[] {
    return arr ? arr.sort((rowa, rowb) => {
      if (rowa.value.sortingKey < rowb.value.sortingKey) {
        return -1;
      } else if (rowa.value.sortingKey > rowb.value.sortingKey) {
        return 1;
      }
      return 0;
    }) : [];
  }

  modifyRowSummaryTable(row, remove = false) {
    console.log('modifyRowSummaryTable');
    if (!row.category || !row.subCategory) {
      console.warn('modifyRowSummaryTable: missing category or subCategory');
      return;
    }
    let modifier = 1;
    if (remove) {
      modifier = -1;
    }
    this.initializeCategory(row);
    // Subtotal category
    row.grandSubTotal = row.grandSubTotal ? row.grandSubTotal : { 'value': 0 };
    row.grandTotalForInstitutionFunds = row.grandTotalForInstitutionFunds ? row.grandTotalForInstitutionFunds : { 'value': 0 };

    this.bphChange.summaryTable.categories['c' + row.category.value].grandSubTotal.value += +(row.grandSubTotal.value) * modifier;
    if (this.confChange.fundsSplitInstitution) {
      this.bphChange.summaryTable.categories['c' + row.category.value].grandTotalForInstitutionFunds.value += +(row.grandTotalForInstitutionFunds.value) * modifier;
      this.bphChange.summaryTable.categories['c' + row.category.value].grandTotalForOtherFunds.value += +(row.grandTotalForOtherFunds.value) * modifier;
    }

    if (!row.isCategoryTotal && !row.isTotal) {
      for (let i = 0; i < this.confChange.numberOfYears; i++) {
        row.expandRows[i] = row.expandRows[i] ? row.expandRows[i] : { 'subTotal': { 'value': 0 } };

        this.bphChange.summaryTable.categories['c' + row.category.value].subCategories['c' + row.subCategory.value].yearRows[i].value += +(row.expandRows[i].subTotal.value) * modifier;
        this.bphChange.summaryTable.categories['c' + row.category.value].yearRows[i].value += +(row.expandRows[i].subTotal.value) * modifier;
        this.bphChange.yearRows[i].value += +(row.expandRows[i].subTotal.value) * modifier;
      }
      // total row
      this.bphChange.summaryTable.categories['c' + row.category.value].subCategories['c' + row.subCategory.value].grandSubTotal.value += +(row.grandSubTotal.value) * modifier;
      if (this.confChange.fundsSplitInstitution) {
        this.bphChange.summaryTable.categories['c' + row.category.value].subCategories['c' + row.subCategory.value].grandTotalForInstitutionFunds.value += +(row.grandTotalForInstitutionFunds.value) * modifier;
        this.bphChange.summaryTable.categories['c' + row.category.value].subCategories['c' + row.subCategory.value].grandTotalForOtherFunds.value += +(row.grandTotalForOtherFunds.value) * modifier;
      }
    }

    console.log("In modify row summary table ",this.bphChange.summaryTable)

    // Big Total
    this.bphChange.grandSubTotal.value += +(row.grandSubTotal.value) * modifier;
    if (this.confChange.fundsSplitInstitution) {
      this.bphChange.grandTotalForInstitutionFunds.value += +(row.grandTotalForInstitutionFunds.value) * modifier;
      this.bphChange.grandTotalForOtherFunds.value += +(row.grandTotalForOtherFunds.value) * modifier;
    }
    this.generateCategoryTeamTable(this.bphChange.budgetTables);
  }

  initializeCategory(row) {
    console.log(row);
    if (!row.category || !row.subCategory) {
      console.error('initializeCategory: missing category or subCategory');
      return;
    }
    if (!this.bphChange.summaryTable.categories['c' + row.category.value]) {
      this.bphChange.summaryTable.categories['c' + row.category.value] = {};
      this.bphChange.summaryTable.categories['c' + row.category.value].sortingKey = row.category.sortingKey;
      this.bphChange.summaryTable.categories['c' + row.category.value].subCategories = {};
      this.bphChange.summaryTable.categories['c' + row.category.value].grandSubTotal = { 'value': 0, 'error': {} };
      if (this.confChange.fundsSplitInstitution) {
        this.bphChange.summaryTable.categories['c' + row.category.value].grandTotalForInstitutionFunds = {
          'value': 0,
          'error': {}
        };
        this.bphChange.summaryTable.categories['c' + row.category.value].grandTotalForOtherFunds = {
          'value': 0,
          'error': {}
        };
      }
      this.bphChange.summaryTable.categories['c' + row.category.value].label = row.category.label;
    }
    if (!this.bphChange.summaryTable.categories['c' + row.category.value].subCategories['c' + row.subCategory.value]) {
      this.bphChange.summaryTable.categories['c' + row.category.value].subCategories['c' + row.subCategory.value] = {};
      this.bphChange.summaryTable.categories['c' + row.category.value].subCategories['c' + row.subCategory.value].label = row.subCategory.label;
    }
    if (!this.bphChange.summaryTable.categories['c' + row.category.value].subCategories['c' + row.subCategory.value].grandSubTotal) {
      this.bphChange.summaryTable.categories['c' + row.category.value].subCategories['c' + row.subCategory.value].grandSubTotal = { 'value': 0, 'error': {} };
    }
    if (this.confChange.fundsSplitInstitution) {
      if (!this.bphChange.summaryTable.categories['c' + row.category.value].subCategories['c' + row.subCategory.value].grandTotalForInstitutionFunds) {
        this.bphChange.summaryTable.categories['c' + row.category.value].subCategories['c' + row.subCategory.value].grandTotalForInstitutionFunds = {
          'value': 0,
          'error': {}
        };
      }
      if (!this.bphChange.summaryTable.categories['c' + row.category.value].subCategories['c' + row.subCategory.value].grandTotalForOtherFunds) {
        this.bphChange.summaryTable.categories['c' + row.category.value].subCategories['c' + row.subCategory.value].grandTotalForOtherFunds = {
          'value': 0,
          'error': {}
        };
      }
    }
    for (let i = 0; i < this.confChange.numberOfYears; i++) {
      if (!this.bphChange.summaryTable.categories['c' + row.category.value].subCategories['c' + row.subCategory.value].yearRows) {
        this.bphChange.summaryTable.categories['c' + row.category.value].subCategories['c' + row.subCategory.value].yearRows = [];
      }
      if (!this.bphChange.summaryTable.categories['c' + row.category.value].subCategories['c' + row.subCategory.value].yearRows[i]) {
        this.bphChange.summaryTable.categories['c' + row.category.value].subCategories['c' + row.subCategory.value].yearRows.push({ 'value': 0, 'error': {} });
      }
      if (!this.bphChange.summaryTable.categories['c' + row.category.value].yearRows) {
        this.bphChange.summaryTable.categories['c' + row.category.value].yearRows = [];
      }
      if (!this.bphChange.summaryTable.categories['c' + row.category.value].yearRows[i]) {
        this.bphChange.summaryTable.categories['c' + row.category.value].yearRows.push({ 'value': 0, 'error': {} });
      }
    }
  }

  toggleTable(table) {
    if (table) {
      table.hidden = !table.hidden;
    }
  }

  changeCat(v, row, list) {
    console.log(v);
    console.log(row);
    console.log(list);
    list.forEach((item) => {
      if (item.value === v) {
        row.category = item;
      }
    });
    this.tempRow.category.children = this.tempRow.category.children.sort((term: any, _term: any) => {
      if (term.sortingKey === _term.sortingKey) {
        return this.sortTerms(term, _term);
      } else {
        return (term.sortingKey < _term.sortingKey ? -1 : 1);
      }
    });


  }
  sortTerms(firstTerm, secondTerm) {
    return (firstTerm.label[this.selectedLanguage]).localeCompare(secondTerm.label[this.selectedLanguage]);
  }


  changeSubCat(v, row, list) {
    console.log(v);
    console.log(row);
    console.log(list);
    list.forEach((item) => {
      if (item.value === v) {
        row.subCategory = item;
      }
    });
  }

  generateUploadConf() {
    this.confChange.uploadConf = {
      'selectedSection': this.confChange.selectedSection,
      'staticDocumentType': this.confChange.staticDocumentType,
      'isReadOnlyField': this.confChange.isReadOnlyField,
      'type': 'upload',
      'formatTypes': [{
        'id': 4301,
        'value': 'PDF',
        'extension': '.pdf'
      }, {
        'id': 4302,
        'value': 'Word',
        'extension': '.docx'
      }, {
        'id': 4303,
        'value': 'Excel',
        'extension': '.xlsx'
      }, {
        'id': 4306,
        'value': 'Legacy Word',
        'extension': '.doc'
      }],
      'isMultipleFileUploadAllowed': true,
      'sizeAdmin': 8,
      'isRequired': false,
      'hasErrorInComponent': false,
      'ifComponentInvalid': false,
      'dialogText': {},
      'tooltipText': {},
      'subBlocks': [],
      'isNew': false,
      'label': this.confChange.documentationLabelInsideTable,
      'typesAdmin': ['.pdf'],
      'selectedContainerName': this.confChange.name,
      'selectedContainerType': this.confChange.type,
      'name': 'upload_documentation'
    };
  }

}
